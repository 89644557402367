import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import SkinnyHeader from "../components/skinnyHeader";
import classes from "./employee.module.css";

export const query = graphql`
	query EmployeeQuery($uid: String) {
		prismic {
			allEmployees(uid: $uid) {
				edges {
					node {
						email {
							_linkType
							... on PRISMIC__ExternalLink {
								url
								_linkType
							}
						}
						header_image
						employee_name
						employee_photo
						employee_title
						full_bio
						phone_number
						linked_in_url {
							_linkType
							... on PRISMIC__ExternalLink {
								_linkType
								url
							}
						}
					}
				}
			}
		}
	}
`;

const Employee = props => {
	const doc = props.data.prismic.allEmployees.edges.slice(0, 1).pop();
	if (!doc) {
		return <div>404</div>;
	}
	const employee = doc.node;
	// Special case to not build Bridge Law Group, LTD.
	if (
		employee &&
		employee.employee_name &&
		employee.employee_name[0] &&
		employee.employee_name[0].text === "Bridge Law Group, Ltd."
	) {
		return null;
	}
	return (
		<Layout>
			<SEO
				title={`${RichText.asText(employee.employee_name)} -
							${RichText.asText(employee.employee_title)}`}
			/>
			{employee.header_image && (
				<SkinnyHeader
					imageUrl={employee.header_image.url}
					label={RichText.asText(employee.employee_name)}
				/>
			)}
			<div className={classes.container}>
				<div className={classes.content}>
					<div className={classes.imageContainer}>
						<img
							className={classes.image}
							alt={RichText.asText(employee.employee_name)}
							src={employee.employee_photo.url}
						/>
						<div>
							<h4 className={classes.contactHeader}>Contact</h4>
							{employee.phone_number && (
								<div className={classes.contactInfo}>
									<strong>Phone: </strong>
									{RichText.asText(employee.phone_number)}
								</div>
							)}
							{employee.email && (
								<div>
									<strong>Email: </strong>
									{employee.email.url.replace("mailto:", "")}
								</div>
							)}
						</div>
					</div>
					<div className={classes.contextContainer}>
						<h2 className={classes.title}>
							{RichText.asText(employee.employee_name)} -{" "}
							{RichText.asText(employee.employee_title)}
						</h2>
						{RichText.render(employee.full_bio)}
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default Employee;
